const initialState = {
  advancedMode: false,
  lastN: 10,
  mode: "democratic",
};

const participantSettings = (state = initialState, action) => {
  switch (action.type) {
    case "TOGGLE_ADVANCED_MODE_PARTICIPANT": {
      const advancedMode = !state.advancedMode;

      return { ...state, advancedMode };
    }

    case "SET_LAST_N_PARTICIPANT": {
      const { lastN } = action.payload;

      return { ...state, lastN };
    }

    case "SET_DISPLAY_MODE_PARTICIPANT": {
      const { mode } = action.payload;

      return { ...state, mode };
    }

    default:
      return state;
  }
};

export default participantSettings;
