import React from "react";
import { withStyles } from "@material-ui/styles";
import { SnackbarProvider } from "notistack";

const styles = (theme) => ({
  variantError: { backgroundColor: "#e45455 !important" },
  variantInfo: { backgroundColor: "rgb(144,144,144) !important" },
});

class SnackProvider extends React.Component {
  render() {
    const { classes, children } = this.props;

    return (
      <SnackbarProvider
        classes={classes}
        anchorOrigin={{
          vertical: "right",
          horizontal: "right",
        }}
      >
        {children}
      </SnackbarProvider>
    );
  }
}

export default withStyles(styles)(SnackProvider);
